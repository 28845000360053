import { inherits } from "util";

// Styles for elements rendered in MDX.
// You can style HTML elements here (e.g. h1, h2) but also components (e.g. Layout).
// Styles here are applied to MDX documents so you don't need global styles. 
// Things like Styled.h1 are styled.

export default {
  root: {
    fontFamily: `body`,
    lineHeight: `body`,
    fontWeight: `body`
  },
  h1: {
    a: {
      color: `inherit`,
      textDecoration: `none`
    }
  },
  h2: {
    a: {
      color: `inherit`,
      textDecoration: `none`
    }
  },
  h3: {
    a: {
      color: `inherit`,
      textDecoration: `none`
    }
  },
  a: {
    color: `text`,
  },
  hr: {
    borderColor: `muted`,
  },
  p: {
    fontFamily: `body`,
    code: {
      fontSize: `inherit`,
    },
  },
  li: {
    code: {
      fontSize: `inherit`,
    },
  },
  blockquote: {
    color: `inherit`,
    borderLeftColor: `inherit`,
    opacity: 0.8,
    "&.translation": {
      fontSize: `1em`,
    },
  },
  // Example of defining styles for components
//   Layout: {
//     backgroundColor: `tomato`
//   }
}