// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("/vercel/workpath0/node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---themes-gatsby-theme-zebra-src-templates-events-js": () => import("/vercel/workpath0/themes/gatsby-theme-zebra/src/templates/events.js" /* webpackChunkName: "component---themes-gatsby-theme-zebra-src-templates-events-js" */),
  "component---themes-gatsby-theme-zebra-src-templates-artwork-js": () => import("/vercel/workpath0/themes/gatsby-theme-zebra/src/templates/artwork.js" /* webpackChunkName: "component---themes-gatsby-theme-zebra-src-templates-artwork-js" */),
  "component---content-pages-about-mdx": () => import("/vercel/workpath0/sites/annawoodhead/content/pages/about.mdx" /* webpackChunkName: "component---content-pages-about-mdx" */),
  "component---content-pages-workshops-mdx": () => import("/vercel/workpath0/sites/annawoodhead/content/pages/workshops.mdx" /* webpackChunkName: "component---content-pages-workshops-mdx" */),
  "component---themes-gatsby-theme-zebra-content-pages-workshops-theme-mdx": () => import("/vercel/workpath0/themes/gatsby-theme-zebra/content/pages/workshops-theme.mdx" /* webpackChunkName: "component---themes-gatsby-theme-zebra-content-pages-workshops-theme-mdx" */)
}

