// You can define named grouped of properties e.g. for buttons.
// Then 

export default {
  a: {
    navlink: {
      display: 'inline-block',
      color: 'inherit',
      textDecoration: 'none',
      
      whiteSpace: 'nowrap',
      flex: 'none',
      px: 0,
      py: 1,
      letterSpacing: '.03em',
      fontSize: 'inherit',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },  
  buttons: {
    primary: {
      color: 'text',
      bg: 'transparent',
      borderColor: 'text',
      '&:hover': {
        color: 'background',
        bg: 'text'
      }
    },
    secondary: {
      color: 'background',
      bg: 'transparent',
      borderColor: 'background',
      '&:hover': {
        color: 'text',
        bg: 'background'
      }
    },
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: 'caps',
    },
  },
  header: {
    //backgroundColor: "red",
  },
  aside: {
    //backgroundColor: "green",
  },
  footer: {
    //backgroundColor: "blue",
  },
  blah: {
    //color: "red"
  },
  layout: {
    header: {
      //backgroundColor: "red",
    },
    menu: {
      //backgroundColor: "green",
    },
    aside: {
      //backgroundColor: "blue"
    },
    main: {
      //backgroundColor: "yellow"
    }
  }

}