// This theme follows the theme-ui specification.
// https://theme-ui.com/theming

import merge from "deepmerge"
import typography from "./typography"
import colors from "./colors"
import sizes from "./sizes"
import styles from "./styles"
import variants from "./variants"

const theme = merge(typography, { 
  initialColorMode: `light`,
  // optionally enable custom properties
  // to help avoid a flash of colors on page load
  useCustomProperties: true,
  colors,
  ...variants,
  sizes,
  styles
})

export default theme
