
import { darken, lighten } from "@theme-ui/color"

// Use @theme-ui/color to adjust a small number of initial colors (see https://theme-ui.com/color)

const white = `#fff`
const offWhite = darken('white', .25) //`#ddd`
const lightGrey = `#aaa`
const grey = `#888`
const darkGrey = `#666`
const offBlack = `#333`
const black = `#000`

export default {
  text: black,          
  background: white,    
  primary: black,       // links
  secondary: grey,      // link hover state
  accent: lightGrey,    // contrasting color for emphasising UI
  muted: offWhite,      // sudued color
  modes: {
    dark: {
      text: white,
      background: black,
      primary: white,
      secondary: grey,
      accent: darkGrey,
      muted: offBlack
    },
  },
}