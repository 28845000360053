module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/workpath0/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.22","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx",".md"],"defaultLayouts":{"pages":"/vercel/workpath0/themes/gatsby-theme-zebra/src/components/mdx-layout-pages.js","posts":"/vercel/workpath0/themes/gatsby-theme-zebra/src/components/mdx-layout-posts.js","default":"/vercel/workpath0/themes/gatsby-theme-zebra/src/components/mdx-layout-pages.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":600}}],"remarkPlugins":[null]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135039457-1","head":false,"anonymize":true,"respectDNT":true,"exclude":[]},
    }]
