import "typeface-cooper-hewitt"
import { toTheme } from "@theme-ui/typography"
import judah from "typography-theme-judah"

judah.headerFontFamily = [ 'Cooper Hewitt', 'sans-serif' ];
judah.bodyFontFamily = [ 'Cooper Hewitt', 'sans-serif' ];
delete judah.googleFonts 

judah.baseFontSize = "14px";
judah.baseLineHeight = 1.5;

export default toTheme(judah)